<template>
  <v-container
    v-if="currentCase"
    style="height: 100%; background-color: red;"
    class="pa-0 ma-0"
  >
    <v-toolbar
      flat
      class="sticky-toolbar"
    >
      <v-btn
        icon
        rounded
        @click.stop="$router.back()"
      >
        <v-icon>
          mdi-arrow-left
        </v-icon>
      </v-btn>
      <v-toolbar-title>{{ $t('cases|case_timeline') }}</v-toolbar-title>
    </v-toolbar>
    <v-card
      height="100%"
    >
      <v-card-text>
        <v-timeline
          align-top
          dense
        >
          <v-timeline-item
            v-for="(item, i) in currentCase.timeline"
            :key="item._id"
            class="my-10"
            :icon="getIcon(item)"
            :color="genColor(i)"
          >
            <v-card
              color="#cfd8dc"
              class="pa-1"
            >
              <div
                class="ml-5"
              >
                {{ convertTime(item.createdAt) }} h
              </div>
              <v-row justify="center">
                <v-col cols="10">
                  <div
                    v-if="item.eventClass && item.eventClass === 'Case'"
                    class="font-weight-regular"
                    style="font-size: 17px;"
                  >
                    <div v-if="item.eventType && item.eventType === 'New case'">
                      <span
                        class="font-weight-regular"
                        style="font-size: 17px;"
                      >
                        {{ $t('common|event') }}:
                      </span>
                      {{ item.content.caseName ? item.content.caseName : '' }} {{ $t(item.content.text) }}
                    </div>
                    <div
                      v-if="item.content.name && item.eventType === 'Case name update'"
                    >
                      <div
                        class="font-weight-regular"
                        style="font-size: 17px;"
                      >
                        {{ $t('common|event') }}: {{ item.content.text }}
                      </div>
                      <div
                        style="font-size: 17px;"
                      >
                        {{ $t('common|enter_new_name') }}:
                        <v-chip
                          class="ma-1 py-n3"
                          color="primary"
                          outlined
                          small
                        >
                          <span style="font-size: 14px;">
                            {{ item.content.name }}
                          </span>
                        </v-chip>
                      </div>
                    </div>
                    <div
                      v-if="item.content.status && item.eventType === 'Case status update'"
                    >
                      <div
                        class="font-weight-regular"
                        style="font-size: 17px;"
                      >
                        {{ $t('common|event') }}: {{ item.content.text }}
                      </div>
                      <div
                        style="font-size: 17px;"
                      >
                        {{ $t('common|status') }}:
                        <v-chip
                          class="ma-1 py-n3"
                          color="primary"
                          outlined
                          small
                        >
                          <span style="font-size: 14px;">
                            {{ item.content.status }}
                          </span>
                        </v-chip>
                      </div>
                    </div>
                    <div
                      v-if="item.content.progress && item.eventType === 'Case progress update'"
                    >
                      <div
                        class="font-weight-regular"
                        style="font-size: 17px;"
                      >
                        {{ $t('common|event') }}: {{ item.content.text }}
                      </div>
                      <div
                        style="font-size: 17px;"
                      >
                        {{ $t('cases|progress') }}:
                        <v-chip
                          class="ma-1 py-n3"
                          color="primary"
                          outlined
                          small
                        >
                          <span style="font-size: 14px;">
                            {{ item.content.progress.toString() }}%
                          </span>
                        </v-chip>
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="item.eventClass && item.eventClass === 'Workbench'"
                    class="font-weight-regular"
                    style="font-size: 17px;"
                  >
                    <div
                      v-if="item.content.workbenchName && item.eventType === 'New workbench'"
                    >
                      <div
                        class="font-weight-regular"
                        style="font-size: 17px;"
                      >
                        {{ $t('common|event') }}:
                      </div>
                      {{ $t(item.content.text) }} {{ item.content.workbenchName ? item.content.workbenchName : '' }}
                      <div
                        style="font-size: 17px;"
                      >
                        {{ $t('mixed|workbench_name') }}:
                        <v-chip
                          class="ma-1 py-n3"
                          color="primary"
                          outlined
                          small
                        >
                          <span style="font-size: 14px;">
                            {{ item.content.workbenchName }}
                          </span>
                        </v-chip>
                      </div>
                    </div>
                    <div
                      v-if="item.content.workbenchName && item.eventType === 'New workbench name'"
                    >
                      <div
                        class="font-weight-regular"
                        style="font-size: 17px;"
                      >
                        {{ $t('common|event') }}:
                      </div>
                      {{ item.content.oldWorkbenchName ? item.content.oldWorkbenchName : '' }} {{ $t(item.content.text) }} {{ item.content.workbenchName ? item.content.workbenchName : '' }}
                      <div
                        style="font-size: 17px;"
                      >
                        {{ $t('mixed|workbench_name') }}:
                        <v-chip
                          class="ma-1 py-n3"
                          color="primary"
                          outlined
                          small
                        >
                          <span style="font-size: 14px;">
                            {{ item.content.workbenchName }}
                          </span>
                        </v-chip>
                      </div>
                    </div>
                    <div
                      v-if="item.content.workbenchDocuments &&
                        (item.eventType === 'New workbench documents' ||
                        item.eventType === 'New workbench required documents'
                        )"
                    >
                      <div
                        class="font-weight-regular"
                        style="font-size: 17px;"
                      >
                        {{ $t('common|event') }}:
                      </div>
                      {{ $t(item.content.text) }}
                      <div>
                        {{ $t('common|workbench') }}:  {{ item.content.workbenchName }}
                      </div>
                      <div
                        style="font-size: 17px;"
                      >
                        {{ $t('documents|new_workbench_documents') }}:
                        <v-chip
                          v-for="(doc, index) in item.content.workbenchDocuments"
                          :key="index"
                          class="ma-1 py-n3"
                          color="primary"
                          outlined
                          small
                        >
                          <v-icon
                            x-small
                            class="mr-1"
                          >
                            mdi-file
                          </v-icon>
                          <span style="font-size: 14px;">
                            {{ doc }}
                          </span>
                        </v-chip>
                      </div>
                    </div>
                    <div
                      v-if="item.eventType === 'Delete required workbench documents'"
                    >
                      <div
                        class="font-weight-regular"
                        style="font-size: 17px;"
                      >
                        {{ $t('common|event') }}:
                      </div>
                      {{ $t(item.content.text) }}
                      <div>
                        {{ $t('common|workbench') }}:  {{ item.content.workbenchName }}
                      </div>
                      <div
                        style="font-size: 17px;"
                      >
                        {{ $t('mixed|required_workbench_documents') }}: {{ item.content.requiredDocument ? item.content.requiredDocument.name : '' }}
                      </div>
                    </div>
                    <div
                      v-if="item.eventType === 'Workbench document removed'"
                    >
                      <div
                        class="font-weight-regular"
                        style="font-size: 17px;"
                      >
                        {{ $t('common|event') }}:
                      </div>
                      {{ $t(item.content.text) }}
                      <div>
                        {{ $t('common|workbench') }}:  {{ item.content.workbenchName }}
                      </div>
                      <div
                        style="font-size: 17px;"
                      >
                        {{ $t('documents|document') }}: {{ item.content.unassignedDocument ? item.content.unassignedDocument: '' }}
                      </div>
                    </div>
                    <div
                      v-if="item.eventType === 'Workbench removed'"
                    >
                      <div
                        class="font-weight-regular"
                        style="font-size: 17px;"
                      >
                        {{ $t('common|event') }}:
                      </div>
                      {{ $t(item.content.text) }}
                      <div>
                        {{ $t('common|workbench') }}:  {{ item.content.deletedWorkbench }}
                      </div>
                    </div>
                  </div>

                  <div v-if="item.eventClass && item.eventClass === 'Sharing'">
                    <div
                      v-if="item.eventType === 'Share' && item.content.sharedWith"
                    >
                      <div
                        class="font-weight-regular"
                        style="font-size: 17px;"
                      >
                        {{ $t('common|event') }}: {{ item.content.text }}
                      </div>
                      <div
                        style="font-size: 17px;"
                      >
                        {{ $t('common|shared_with') }}:
                        <v-chip
                          class="ma-1 py-n3"
                          color="primary"
                          outlined
                          small
                        >
                          <v-avatar
                            size="18px"
                            class="ml-n3 mr-1"
                          >
                            <!-- <img
                          v-if="item.author && item.author.avatar"
                          alt="Avatar"
                          :src="`${cfg.baseURL}downloadAvatar/${item.author._id}/${item.author.avatar.raw.filename}`"
                        > -->
                            <v-avatar>
                              <v-icon
                                color="primary"
                                small
                              >
                                mdi-account
                              </v-icon>
                            </v-avatar>
                          </v-avatar>
                          <span style="font-size: 14px;">
                            {{ getItemName(item) }}
                          </span>
                        </v-chip>
                      </div>
                    </div>
                    <div
                      v-if="item.eventType === 'Unshare'"
                    >
                      <div
                        class="font-weight-regular"
                        style="font-size: 17px;"
                      >
                        {{ $t('common|event') }}: {{ item.content.text }}
                      </div>
                      <div
                        style="font-size: 17px;"
                      >
                        {{ $t('message|stopped_sharing_with') }}:
                        <v-chip
                          class="ma-1 py-n3"
                          color="primary"
                          outlined
                          small
                        >
                          <v-avatar
                            size="18px"
                            class="ml-n3 mr-1"
                          >
                            <!-- <img
                          v-if="item.author && item.author.avatar"
                          alt="Avatar"
                          :src="`${cfg.BaseURL}downloadAvatar/${item.author._id}/${item.author.avatar.raw.filename}`"
                        > -->
                            <v-avatar>
                              <v-icon
                                color="primary"
                                small
                              >
                                mdi-account
                              </v-icon>
                            </v-avatar>
                          </v-avatar>
                          <span style="font-size: 14px;">
                            {{ getItemName(item) }}
                          </span>
                        </v-chip>
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="item.eventClass === 'Documents'"
                  >
                    <div v-if="item.eventType === 'Assign case documents'">
                      <div
                        class="font-weight-regular"
                        style="font-size: 17px;"
                      >
                        {{ $t('common|event') }}: {{ item.content.text }}
                      </div>
                      <div
                        style="font-size: 17px;"
                      >
                        {{ $t('documents|documents') }}:
                        <v-chip
                          v-for="(docName, index) in item.content.documents"
                          :key="index"
                          class="ma-1 py-n3"
                          color="primary"
                          outlined
                          small
                        >
                          <v-icon
                            x-small
                            class="mr-2"
                          >
                            mdi-file
                          </v-icon>
                          <span style="font-size: 14px;">
                            {{ docName }}
                          </span>
                        </v-chip>
                      </div>
                    </div>
                    <div
                      v-if="item.eventType === 'Unassign case document' && item.content.document"
                    >
                      <div
                        class="font-weight-regular"
                        style="font-size: 17px;"
                      >
                        {{ $t('common|event') }}: {{ item.content.text }}
                      </div>
                      <div
                        style="font-size: 17px;"
                      >
                        {{ $t('documents|document') }}:
                        <v-chip
                          class="ma-1 py-n3"
                          color="primary"
                          outlined
                          small
                        >
                          <v-icon
                            x-small
                            class="mr-2"
                          >
                            mdi-file
                          </v-icon>
                          <span style="font-size: 14px;">
                            {{ item.content.document }}
                          </span>
                        </v-chip>
                      </div>
                    </div>
                    <div v-if="item.eventType === 'Required case documents update'">
                      <div
                        class="font-weight-regular"
                        style="font-size: 17px;"
                      >
                        {{ $t('common|event') }}: {{ item.content.text }}
                      </div>
                      <div
                        style="font-size: 17px;"
                      >
                        {{ $t('documents|documents') }}:
                        <v-chip
                          v-for="(reqDoc, index) in item.content.requiredCaseDocuments"
                          :key="index"
                          class="ma-1 py-n3"
                          color="primary"
                          outlined
                          small
                        >
                          <v-icon
                            x-small
                            class="mr-2"
                          >
                            mdi-file-outline
                          </v-icon>
                          <span style="font-size: 14px;">
                            {{ reqDoc.name }}
                          </span>
                        </v-chip>
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="item.eventClass === 'Template'"
                  >
                    <div v-if="item.eventType === 'New template' || item.eventType === 'Delete template'">
                      <div
                        class="font-weight-regular"
                        style="font-size: 17px;"
                      >
                        {{ $t('common|event') }}: {{ item.content.text }}
                      </div>
                      <div
                        style="font-size: 17px;"
                      >
                        {{ $t('templates|template_name') }}:
                        <v-chip
                          class="ma-1 py-n3"
                          color="primary"
                          outlined
                          small
                        >
                          <v-icon
                            x-small
                            class="mr-1"
                          >
                            mdi-file-hidden
                          </v-icon>
                          <span style="font-size: 14px;">
                            {{ item.content.templateName }}
                          </span>
                        </v-chip>
                      </div>
                      <div
                        style="font-size: 17px;"
                      >
                        {{ $t('mixed|workbench_name') }}:
                        <v-chip
                          class="ma-1 py-n3"
                          color="primary"
                          outlined
                          small
                        >
                          <v-icon
                            x-small
                            class="mr-1"
                          >
                            mdi-file-hidden
                          </v-icon>
                          <span style="font-size: 14px;">
                            {{ item.content.workbenchName }}
                          </span>
                        </v-chip>
                      </div>
                    </div>
                    <div>
                      <div
                        style="font-size: 17px;"
                      >
                        {{ $t('common|author') }}:
                        <v-chip
                          class="ma-1 py-n3"
                          color="primary"
                          outlined
                          small
                        >
                          <v-avatar
                            size="18px"
                            class="ml-n3 mr-1"
                          >
                            <img
                              v-if="item.author && item.author.avatar"
                              alt="Avatar"
                              :src="`${cfg.baseURL}downloadAvatar/${item.author._id}/${item.author.avatar.raw.filename}`"
                            >
                            <v-avatar
                              v-else
                            >
                              <v-icon
                                color="primary"
                                small
                              >
                                mdi-account
                              </v-icon>
                            </v-avatar>
                          </v-avatar>
                          <span
                            style="font-size: 17px;"
                          >
                            {{ $t('common|author') }}:
                          </span>
                          <span
                            v-if="item.author.accountData.firstName && item.author.accountData.lastName"
                            style="font-size: 14px;"
                          >
                            {{ item.author.accountData.firstName + ' ' + item.author.accountData.lastName }}
                          </span>
                          <span
                            v-else
                            style="font-size: 14px;"
                          >
                            {{ item.author.accountName }}
                          </span>
                        </v-chip>
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="item.eventClass === 'Client'"
                  >
                    <div v-if="item.eventType === 'Case client update'">
                      <div
                        class="font-weight-regular"
                        style="font-size: 17px;"
                      >
                        {{ $t('common|event') }}: {{ item.content.text }}
                      </div>
                      <div
                        style="font-size: 17px;"
                      >
                        {{ $t('common|name') }}:
                        <v-chip
                          class="ma-1 py-n3"
                          color="primary"
                          outlined
                          small
                        >
                          <v-icon
                            x-small
                            class="mr-1"
                          >
                            mdi-account-convert-outline
                          </v-icon>
                          <span style="font-size: 14px;">
                            {{ item.content.name }}
                          </span>
                        </v-chip>
                      </div>
                      <div
                        style="font-size: 17px;"
                      >
                        {{ $t('common|author') }}:
                        <span
                          v-if="item.author.accountData.firstName && item.author.accountData.lastName"
                          style="font-size: 17px;"
                        >
                          {{ item.author.accountData.firstName + ' ' + item.author.accountData.lastName }}
                        </span>
                        <span
                          v-else
                          style="font-size: 17px;"
                        >
                          {{ item.author.accountName }}
                        </span>
                      </div>
                    </div>
                  </div>

                  <v-slide-x-transition
                    group
                  >
                    <v-card
                      v-for="(c, index) in item.comments"
                      :key="index"
                      class="pa-2 my-3"
                      flat
                      outlined
                      elevation="5"
                    >
                      <v-chip
                        class="ma-1 py-n3"
                        color="primary"
                        outlined
                        x-small
                      >
                        <v-avatar
                          size="12px"
                          class="ml-n3 mr-1"
                        >
                          <img
                            v-if="c.createdBy.avatar"
                            alt="Avatar"
                            :src="`${cfg.baseURL}downloadAvatar/${c.createdBy._id}/${c.createdBy.avatar.raw.filename}`"
                          >
                          <v-avatar
                            v-else
                          >
                            <v-icon
                              color="primary"
                              small
                            >
                              mdi-account
                            </v-icon>
                          </v-avatar>
                        </v-avatar>
                        <span
                          v-if="c.createdBy.accountData.firstName && c.createdBy.accountData.lastName"
                          style="font-size: 14px;"
                        >
                          {{ c.createdBy.accountData.firstName + ' ' + c.createdBy.accountData.lastName }}
                        </span>
                        <span
                          v-else
                          class="text-truncate"
                          style="font-size: 14px;"
                        >
                          {{ c.createdBy.accountName }}
                        </span>
                      </v-chip>

                      <div
                        style="font-size: 15px;"
                      >
                        {{ c.comment }}
                      </div>

                      <span
                        style="font-size: 14px;"
                      >
                        {{ convertTime(c.time) }}h
                      </span>
                      <v-icon
                        v-if="c.createdBy._id === account._id"
                        small
                        class="ml-4"
                        @click="deleteTimelineComment(c, item)"
                      >
                        mdi-close
                      </v-icon>
                    </v-card>
                  </v-slide-x-transition>

                  <v-text-field
                    v-model="item.content.comment"
                    hide-details
                    flat
                    dense
                    :label="$t('actions|leave_comment')"
                    class="mx-auto my-5"
                    @keydown.enter="comment"
                  />
                  <v-btn
                    class="mx-0"
                    color="primary"
                    x-small
                    rounded
                    @click="comment(item, item.content.comment)"
                  >
                    {{ $t('common|post') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import {
  mdiBriefcaseMinus,
  mdiClipboardRemove,
  mdiFileRemove,
  mdiFileRemoveOutline,
  mdiAccountConvertOutline
} from '@mdi/js'
import moment from 'moment'
import cfg from '@/config'
const createdAtFormat = date => moment(date)

export default {
  data: () => ({
    events: [],
    input: [],
    caseTimeLineDialog: false,
    icons: {
      unshare: mdiBriefcaseMinus
    },
    cfg,
    currentCase: null
  }),

  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company,
      clients: state => state.clients.clients,
      documents: state => state.documents.documents,
      cases: state => state.cases.cases,
      companyCases: state => state.companyCases.companyCases,
      groupCases: state => state.groupCases.groupCases
    }),
    comments () {
      let items = []
      this.currentCase.timeline.forEach(item => {
        if (item.comments) {
          items.push(item.comments)
        }
      })
      return items
    },
    currentTimeline () {
      const slicedTimeline = this.currentCase.timeline.slice(1)
      return slicedTimeline
    }
  },
  mounted () {
    if (this.$route.params.currentCase) {
      this.currentCase = this.$route.params.currentCase
    } else if (this.$route.params._id) {
      if (!this.cases || !this.companyCases || !this.groupCases) this.$router.back()
      this.currentCase = this.cases.find(c => c._id === this.$route.params._id) ||
      this.companyCases.find(c => c._id === this.$route.params._id) ||
      this.groupCases.find(c => c._id === this.$route.params._id)
    } else {
      this.$router.back()
    }
  },
  methods: {
    ...mapActions({
      commentCaseTimeline: 'cases/commentCaseTimeline',
      deleteCommentTimeline: 'cases/deleteCommentTimeline'
    }),
    async comment (item, input) {
      const time = Date.now()
      const _id = this.currentCase._id
      const timelineItemId = item._id
      const payload = {
        createdBy: this.account._id,
        comment: input,
        time: time
      }
      await this.commentCaseTimeline({ _id, timelineItemId, payload })
    },
    async deleteTimelineComment (comment, timelineItem) {
      const _id = this.currentCase._id
      const timelineItemId = timelineItem._id
      const payload = {
        deleteComment: true,
        commentId: comment._id
      }
      await this.deleteCommentTimeline({ _id, timelineItemId, payload })
    },
    convertTime (time) {
      // only time => add .format('HH:mm')
      return createdAtFormat(time).format('DD/MM/YYYY - HH:mm')
    },
    getItemName (item) {
      let itemName
      if (item.content.onSharedWith === 'Group') {
        this.company.groups.forEach(group => {
          if (group._id === item.content.sharedWith) {
            itemName = group.groupName
          }
        })
      }
      if (item.content.onSharedWith === 'Company') {
        itemName = this.company.companyData.companyName
      }
      if (item.content.onSharedWith === 'Client') {
        // get only the real clients (with client accounts)
        let realClients = []
        this.clients.forEach(client => {
          if (client.clientAccount) {
            realClients.push(client)
          }
        })
        const client = realClients.find(cli => cli.clientAccount._id === item.content.sharedWith)
        if (client && client.clientType === 'individual' && client.realClient) {
          itemName = client.clientAccount.accountData.firstName ? client.clientAccount.accountData.firstName + ' ' + client.clientAccount.accountData.lastName : client.clientAccount.accountName
        } else if (client && client.clientType === 'company') {
          // TO DO Company client type
        }
      }
      if (item.eventType === 'Unshare') {
        if (item.content.onUnshareFrom === 'Group') {
          this.company.groups.forEach(group => {
            if (group._id === item.content.unshareFrom) {
              itemName = group.groupName
            }
          })
        }
        if (item.content.onUnshareFrom === 'Company') {
          if (this.company._id === item.content.unshareFrom) {
            itemName = this.company.companyData.companyName
          }
        }
      }
      return itemName
    },
    getIcon (item) {
      if (item.eventType === 'Share') {
        return 'mdi-briefcase-account'
      }
      if (item.eventType === 'Unshare') {
        return 'mdi-briefcase-minus'
      }
      if (item.eventType === 'New case') {
        return 'mdi-briefcase-plus'
      }
      if (item.eventType === 'Assign case documents') {
        return 'mdi-file-plus'
      }
      if (item.eventType === 'Unassign case document') {
        return 'mdi-file-remove'
      }
      if (item.eventType === 'Case name update') {
        return 'mdi-case-sensitive-alt'
      }
      if (item.eventType === 'Case status update') {
        return 'mdi-format-list-checks'
      }
      if (item.eventType === 'Case progress update') {
        return 'mdi-progress-check'
      }
      if (item.eventType === 'New workbench') {
        return 'mdi-bulletin-board'
      }
      if (item.eventType === 'New workbench documents') {
        return 'mdi-file-plus'
      }
      if (item.eventType === 'New workbench required documents') {
        return 'mdi-file-plus'
      }
      if (item.eventType === 'New workbench name') {
        return 'mdi-format-letter-case'
      }
      if (item.eventType === 'Workbench document removed') {
        return mdiFileRemove
      }
      if (item.eventType === 'Delete required workbench documents') {
        return mdiFileRemove
      }
      if (item.eventType === 'Workbench removed') {
        return mdiClipboardRemove
      }
      if (item.eventType === 'Required case documents update') {
        if (item.content.text === 'timeline|required_documents_have_been_added') return 'mdi-file-plus-outline'
        if (item.content.text === 'timeline|required_documents_have_been_deleted') return 'mdi-file-remove-outline'
      }
      if (item.eventType === 'New template') {
        return 'mdi-file-hidden'
      }
      if (item.eventType === 'Delete template') {
        return mdiFileRemoveOutline
      }
      if (item.eventClass === 'Client') {
        return mdiAccountConvertOutline
      }
    },
    genColor (index) {
      if (index % 2 === 0) {
        return '#f57c00'
      } else {
        return '#00acc1'
      }
    }
  }
}
</script>

<style scoped>
.sticky-toolbar {
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  top: 48px;
  z-index: 3;
}
</style>
